import axios from '@/utils/request'

// 余额账户列表
export function getMerchants(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/merchants`,
    method: 'get',
    params
  })
}

// 余额账户列表
export function getMerchantsDetail(thirdAccountId) {
  return axios({
    url: `/manager/trade/allocfund/merchants/${thirdAccountId}`,
    method: 'get'
  })
}
// 余额账户列表-转账/结算
export function getfeeCheck(mchId) {
  return axios({
    url: `/manager/account/feeCheck/${mchId}`,
    method: 'get'
  })
}
//余额账户列表-结算
export function getSetTraDataHome(mchId) {
  return axios({
    url: `/manager/account/balance/${mchId}`,
    method: 'get'
  })
}

//余额账户列表-转账-收款方列表
export function getPayeeListHome(mchId,params) {
  return axios({
    url: `/manager/account/allocMch/${mchId}/list`,
    method: 'get',
    params
  })
}

//余额账户列表-转账-单笔
export function getAltMchTransfer(data) {
  return axios({
    url: `/manager/trade/allocfund/pays/altMchTransfer`,
    method: 'post',
    data
  })
}

//余额账户列表-转账-多笔
export function getAltMchBatchtransfer(data) {
  return axios({
    url: `/manager/trade/allocfund/pays/altMchBatchtransfer`,
    method: 'post',
    data
  })
}

//余额账户列表-结算
export function getAltwithdrawSettlement(data) {
  return axios({
    url: `/manager/trade/allocfund/pays/withdraw`,
    method: 'post',
    data
  })
}


// 入账查询
export function getIncomeOrderList(params) {
  return axios({
    url:`/manager/trade/pay/incomeOrder`,
    method:'get',
    params
  })
}

// 入账查询-导出
export function getIncomeOrderListExport(params) {
  return axios({
    url:`/manager/trade/pay/incomeOrder/export`,
    method:'get',
    responseType:'arraybuffer',
    params
  })
}

// 入账查询-详情
export function getEntryInquiryrDetail(id) {
  return axios({
    url: `/manager/trade/pay/incomeOrder/detail/${id}`,
    method: 'get'
  })
}

//结算查询
export function getSettleorders(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/settleorders`,
    method: 'get',
    params
  })
}

export function getSettleordersSearch(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/settleorders/search`, 
    method: 'get',
    params
  })
}

//结算查询-订单同步
export function setSettleordersSync(settleOrderId, type) {
  return axios({
    url: `/manager/trade/allocfund/settleorders/${settleOrderId}/sync`,
    method: 'get',
    Headers: {
      'type': type
    }
  })
}

export function setSettleordersNotify(settleOrderId) {
  return axios({
    url: `/manager/trade/allocfund/settleorders/${settleOrderId}/notify`,
    method: 'post'
  })
}

export function exportSettleorders(params) {
  return axios({
    responseType: 'blob',
    url: `/manager/trade/allocfund/settleorders/export`,
    method: 'post',
    params
  })
}

// 结算查询-详情
export function getSettleordersDetail(settleOrderId) {
  return axios({
    url: `/manager/trade/allocfund/settleorders/${settleOrderId}`,
    method: 'get'
  })
}

// 账户转账查询
export function getTransferorder(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/transferorder`,
    method: 'get',
    params
  })
}
// 账户转账搜索
export function getTransferorderSearch(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/transferorder/search`,
    method: 'get',
    params
  })
}
// 账户转账搜索
export function getTransferordersDetail(orderId) {
  return axios({
    url: `/manager/trade/allocfund/transferorder/${orderId}`,
    method: 'get'
  })
}
// 账户转账导出
export function exportTransferorder(params) {
  return axios({
    responseType: 'blob',
    url: `/manager/trade/allocfund/transferorder/export`,
    method: 'post',
    params
  })
}
// 账户转账同步
export function setTransferSync(orderId) {
  return axios({
    url: `/manager/trade/allocfund/transferorder/${orderId}/sync`,
    method: 'get'
  })
}
// 账户转账通知
export function setTransferNotify(orderId) {
  return axios({
    url: `/manager/trade/allocfund/transferorder/${orderId}/notify`,
    method: 'post'
  })
}

// 账户转账查询 - 批量转账订单
export function getTransferBatchOrder(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/transferorder/batch`,
    method: 'get',
    params
  })
}

// 账户转账查询 - 批量转账 - 同步
export function setTransferBatchSync(orderId, type) {
  return axios({
    url: `/manager/trade/allocfund/transferorder/batch/${orderId}/sync`,
    method: 'get',
    Headers: {
      'type': type
    }
  })
}


// 账户转账查询 - 批量转账 - 通知
export function setTransfeBatchNotify(orderId, type) {
  return axios({
    url: `/manager/trade/allocfund/transferorder/batch/${orderId}/notify`,
    method: 'post',
    Headers: {
      'type': type
    }
  })
}

// 账户转账查询 - 批量转账 - 详情
export function getTransferBatchOrdersDetail(orderId) {
  return axios({
    url: `/manager/trade/allocfund/transferorder/batch/${orderId}`,
    method: 'get'
  })
}

// 账户转账查询 - 批量转账 - 订单搜索 （mchBatchNo 批次转账订单号、plfBatchNo 批次转账流水号、upsBatchNo 三方批次转账订单号）
export function getTransferBatchOrderSearch(params) {
  params.loading = true
  return axios({
    url: `/manager/trade/allocfund/transferorder/batch/search`,
    method: 'get',
    params
  })
}