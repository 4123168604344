<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="title">订单信息</h5>
          <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
        </div>
        <ul class="listBox">
          <li>
            <span>{{ tabActive == 0 ? "转账订单号" : "批次转账订单号"}}</span> 
            <i class="hidden1">{{detail.mchOrderNo?detail.mchOrderNo:''}}</i>
          </li>
          <li>
            <span>{{ tabActive == 0 ? "转账流水号" : "批次转账流水号" }} </span>
            <i class="hidden1">{{detail.plfOrderNo?detail.plfOrderNo:''}}</i>
          </li>
          <li>
            <span>{{ tabActive == 0 ? "三方转账订单号" : "三方批次转账订单号" }} </span>
            <i class="hidden1">{{detail.upsOrderNo?detail.upsOrderNo:''}}</i>
          </li>
          <li>
            <span>转账说明</span>
            <i class="hidden1">
              <Tooltip
                effect="light"
                style="height: 20px"
                :content="detail.transferRemark"
                placement="top-start">
                {{ detail.transferRemark }}
              </Tooltip>
            </i>
          </li>
          <li>
            <span>发起方名称</span>
            <i class="hidden1">
              <Tooltip
                effect="light"
                style="height: 20px"
                :content="detail.sourceMchName"
                placement="top-start">
                {{ detail.sourceMchName }}
              </Tooltip>
            </i>
          </li>
          <li>
            <span>发起方编号</span>
            <i class="hidden1">{{detail.sourceMchNo?detail.sourceMchNo:''}}</i>
          </li>
          <li v-if="tabActive == 0">
            <span>收款方名称</span>
            <i class="hidden1">
              <Tooltip
                effect="light"
                style="height: 20px"
                :content="detail.targetMchName"
                placement="top-start">
                {{detail.targetMchName}}
              </Tooltip>
            </i>
          </li>
          <li v-if="tabActive == 0">
            <span>收款方编号</span>
            <i class="hidden1">{{detail.targetMchNo?detail.targetMchNo:''}}</i>
          </li>
          <li>
            <span>创建时间</span>
            <i class="hidden1">{{detail.createTime?detail.createTime:''}}</i>
          </li>
          <li>
            <span>交易时间</span>
            <i class="hidden1">{{detail.tradeTime?detail.tradeTime:''}}</i>
          </li>
          <li>
            <span>交易状态</span>
            <i
              class="hidden1"
            >{{orderStatus[detail.orderStatus]?orderStatus[detail.orderStatus]:''}}</i>
          </li>
          <li>
            <span>转账金额</span>
            <i class="hidden1">{{detail.transferAmount/100 | formatMoney}}</i>
          </li>
          <li>
            <span>手续费</span>
            <i class="hidden1">{{detail.transferFee/100 | formatMoney}}</i>
          </li>
          <li>
            <span>总金额</span>
            <i class="hidden1">{{detail.transferDebitAmount/100 | formatMoney}}</i>
          </li>
          <li>
            <span>失败描述</span>
            <i class="hidden1">
              <Tooltip
                effect="light"
                style="height: 20px"
                :content="detail.resultMessage"
                placement="top-start">
                {{detail.resultMessage}}
              </Tooltip>
            </i>
          </li>
        </ul>
      </div>
      <div v-if="tabActive == 1">
        <div class="desc">
          <h5 class="title">批次转账明细</h5>
        </div>
        <div class="tab1">
          <table class="tab">
            <tr class="tab_title">
              <td>
                <p>商户订单号</p>
                <p>转账流水号</p>
              </td>   
              <td>
                <p>转账接收方编号</p>
              </td>
              <td>
                <p>转账金额</p>
              </td>
              <td>
                <p>实际扣款金额</p>
              </td>
              <td>
                <p>转账手续费</p>
              </td> 
              <td>
                <p>转账备注</p>
              </td>                   
            </tr>
            <tr v-for="(v, i) in detail.batchTransferDetailList" :key="i">
              <td>
                <p>{{ v.mch_order_no }}</p>
                <p>{{ v.trx_no }}</p>
              </td>
              <td>
                <p>{{ v.target_mch_no }}</p>
              </td>
              <td>
                <p>{{ (v.transfer_amount / 100) | formatMoney }}</p>
              </td>  
              <td>
                <p>{{ (v.transfer_debit_amount / 100) | formatMoney }}</p>
              </td> 
              <td>
                <p>{{ (v.transfer_fee / 100) | formatMoney }}</p>
              </td>
              <td>
                <p>{{ v.transfer_remark }}</p>
              </td>                                      
            </tr>
          </table>
        </div>
      </div>
      <!-- 
      <div class="desc">
        <h5 class="title">转账通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
          </tr>
          <tr v-for="(v,i) in notices" :key="i">
            <td>
              <p>{{v.notifyTime}}</p>
            </td>
            <td>
              <p>{{v.reachStatus?'已到达':'未响应'}}</p>
            </td>
            <td>
              <p>{{notifyStatus[v.notifyStatus]}}</p>
            </td>
          </tr>
        </table>
      </div> -->
    </div>
  </div>
</template>
<script>
import { getTransferordersDetail , getTransferBatchOrdersDetail } from "@/api/basic/balance.js";
export default {
  data() {
    return {
      detail: "",
      notices: [],
      crumbs: "交易详情",
      orderStatus: {
        PROCESSING: "处理中",
        FAIL: "交易失败",
        SUCCESS: "交易成功"
      },
      notifyType: {
        PLATFORM: "平台",
        THIRD: "第三方"
      },
      notifyStatus: {
        SUCCESS: "成功",
        FAIL: "失败"
      },
      productCode: {
        1: "平台转分账方",
        2: "分账方转分账方",
        3: "分账方转平台"
      },
      tabActive: "",
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      this.tabActive = this.$route.query.tabActive;
      if(this.tabActive == "0"){
        getTransferordersDetail(this.$route.query.id).then(res => {
          if (res) {
            this.detail = res.resultData.detail;
            this.notices = res.resultData.notices;
          }
        });
      }else{
        getTransferBatchOrdersDetail(this.$route.query.id).then(res => {
          if (res) {
            this.detail = res.resultData.detail;
            this.notices = res.resultData.notices;
          }
        });
      }
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    }
  }
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
</style>
